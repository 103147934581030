<template>
  <div>
    <v-card>
      <v-app-bar color="orange" elevate-on-scroll dark>
        <v-app-bar-nav-icon @click="openDrawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Work Scheduler</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="redirect('Info')">
          <v-icon>mdi-card-account-details-outline</v-icon>
        </v-btn>
        <v-btn icon @click="logout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>

      <v-navigation-drawer v-model="showDrawer" absolute app temporary class="orange accent-1">
        <v-list nav dense>
          <v-list-item-group mandatory v-model="selectedNavIndex" active-class="orange accent-2">
            <v-list-item v-for="r in navRoutes" :key="r.text" @click="redirect(r.route)">
              <v-list-item-icon>
                <v-icon>{{ r.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ r.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

    </v-card>
  </div>
</template>

<script>

import UserService from "@/service/UserService";

export default {
  name: "NavBar",
  props: ['currentRoute'],
  data: function () {
    return {
      showDrawer: false,
      selectedNavIndex: null,
      navRoutes: null,
      showLicenseDialog: false,
    }
  },
  created() {
    this.populateNavRoute();
    if (this.currentRoute !== undefined)
      this.selectItemInNav();
  },

  methods: {
    openDrawer() {
      this.showDrawer = true;
    },

    //shows less routes/options when logged in as user
    populateNavRoute() {
      if (UserService.isCurrentUserAdmin()) {
        this.navRoutes = [
          {
            text: 'Today\'s Schedule',
            icon: 'mdi-wrench-clock',
            route: 'Schedule'
          },
          {
            text: 'Schedules filter',
            icon: 'mdi-filter-variant',
            route: 'ScheduleFilter'
          },
          {
            text: 'Notes',
            icon: 'mdi-message-bulleted',
            route: 'Notes'
          },
          {
            text: 'Users',
            icon: 'mdi-account',
            route: 'Users'
          },
          {
            text: 'Settings',
            icon: 'mdi-cog-outline',
            route: 'Settings'
          },
          {
            text: 'Change password',
            icon: 'mdi-lock-reset',
            route: 'ResetPassword'
          },
          {
            text: 'Info',
            icon: 'mdi-information-outline',
            route: 'Info'
          },
          {
            text: 'Logout',
            icon: 'mdi-logout',
            route: 'Login'
          },
        ];
      } else {
        this.navRoutes = [
          {
            text: 'Today\'s Schedule',
            icon: 'mdi-wrench-clock',
            route: 'Schedule'
          },
          {
            text: 'My Schedules',
            icon: 'mdi-filter-variant',
            route: 'ScheduleFilter'
          },
          {
            text: 'Notes',
            icon: 'mdi-message-bulleted',
            route: 'Notes'
          },
          {
            text: 'Change password',
            icon: 'mdi-lock-reset',
            route: 'ResetPassword'
          },
          {
            text: 'Logout',
            icon: 'mdi-logout',
            route: 'Login'
          },
        ];
      }
    },

    selectItemInNav() {
      for (let i = 0, iLen = this.navRoutes.length; i < iLen; i++) {
        if (this.navRoutes[i].route === this.currentRoute)
          this.selectedNavIndex = i;
      }
    },

    redirect(navRoute) {
      if (navRoute === 'Login')
        this.logout();
      else
        if (navRoute !== this.currentRoute)
          this.$router.push({name: navRoute});
    },

    logout() {
      UserService.logout();
      this.$router.push({name: 'Login'});
    }
  },
}
</script>